import { SignupCouponCodes } from 'store/crossSell/crossSell.types';

export const SIGNUP_COUPON_CODES: {
  [key in SignupCouponCodes]?: { amount: string | null; percentage: string | null };
} = {
  '4health25': {
    amount: null,
    percentage: '25'
  },
  '4health50': {
    amount: null,
    percentage: '50'
  },
  xsell100off: {
    amount: null,
    percentage: '100'
  },
  GLP250OFF: {
    amount: null,
    percentage: '25'
  },
  GLP150OFF: {
    amount: null,
    percentage: '50'
  },
  '100OFFSEM': {
    amount: '100',
    percentage: null
  },
  '200OFFBUNDLE': {
    amount: '200',
    percentage: null
  },
  GLP125REC: {
    amount: null,
    percentage: '25'
  },
  '74OFF1MO': {
    amount: '74',
    percentage: null
  },
  GLP150REC: {
    amount: null,
    percentage: '50'
  },
  GLP133REC: {
    amount: null,
    percentage: '33'
  },
  CAFIRE: {
    amount: null,
    percentage: '100'
  },
  GLP1F350REC: {
    amount: null,
    percentage: '50'
  },
  GLP1F100: {
    amount: '100',
    percentage: null
  }
};

export const CHANGE_PLAN_COUPON_OPTIONS = [
  { label: '200OFFBUNDLE - $200.00 off', value: '200OFFBUNDLE' },
  { label: 'None', value: '' }
];
