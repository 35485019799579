import { useEffect, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import InputField from 'components/forms/controlled/InputField';
import EducationGroupInputs from 'components/modals/components/EditProfileForm/EducationGroupInputs';
import { RoleShortName } from 'enums/role';
import { Option } from 'models/form.types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  useGetLicensedInQuery,
  useGetSpecialtyAreasQuery,
  useGetTimeZoneQuery
} from 'store/lookup/lookupSlice';
import { validation } from 'utils/helpers';
import { EDIT_POPUP_PERMISSION, hasPermission } from 'utils/popupsContentPermission';
import { NUMBER_REGEXP, URL_REGEXP } from 'utils/regExp';

import { AdditionalSectionProps } from './additionalSection.types';
import {
  availableLanguages,
  licenseTypes as licenseTypesOptions
} from '../../editProfileForm.settings';

const AdditionalSection: React.FC<AdditionalSectionProps> = ({
  labelClasses,
  currentRole,
  isMyProfile,
  licensedStates,
  languages,
  licenseTypes
}) => {
  const { control, formState } = useFormContext();
  const { data: specialtyAreas } = useGetSpecialtyAreasQuery();
  const { data: licensedInData } = useGetLicensedInQuery();
  const { data: timeZoneData } = useGetTimeZoneQuery();

  const [specialtyAreasOptions, setSpecialtyAreasOptions] = useState<Option[]>([]);
  const [licensedInOptions, setLicensedInOptions] = useState<Option[]>([]);

  const isPhysician =
    currentRole === RoleShortName.Physician || currentRole === RoleShortName.MasterPhysician;

  useEffect(() => {
    if (licensedInData) {
      const licensedInDataOptions = licensedInData.map(({ label }) => ({ label, value: label }));
      setLicensedInOptions(licensedInDataOptions);
    }
  }, [licensedInData]);

  useEffect(() => {
    if (specialtyAreas) {
      const areaOptions = specialtyAreas.map((area) => ({ label: area.name, value: area._id }));
      setSpecialtyAreasOptions(areaOptions);
    }
  }, [specialtyAreas]);

  const shouldShowLanguages =
    isPhysician ||
    currentRole === RoleShortName.CustomerService ||
    currentRole === RoleShortName.MedicalAssistant;

  const multiselectLabelClasses = 'max-w-[120px] self-start';

  return (
    <>
      <InputField
        name="npi"
        label="NPI #"
        control={control}
        labelDirection="row"
        placeholder="Enter NPI #..."
        type="number"
        errors={formState.errors.npi}
        rules={validation('NPI', NUMBER_REGEXP)}
        maxLength={10}
      />

      <div className="col-span-2 h-px w-full bg-gray-200" />

      {!isMyProfile && isPhysician && (
        <div className="flex py-2.5">
          <p className="min-w-[120px] text-sm font-semibold text-gray-700">Is Async Enabled?</p>
          <Controller
            control={control}
            defaultValue={false}
            name="asyncProviderEnabled"
            render={({ field: { value, name, onChange } }) => (
              <Common.Checkbox name={name} checked={value} onChange={onChange} />
            )}
          />
        </div>
      )}

      {isPhysician && (
        <div className="flex py-2.5">
          <p className="max-w-[120px] text-wrap text-sm font-semibold text-gray-700">
            Advanced Call Logging
          </p>
          <Controller
            control={control}
            defaultValue={true}
            name="advancedCallLogging"
            render={({ field: { value, name, onChange } }) => (
              <Common.Checkbox name={name} checked={value} onChange={onChange} />
            )}
          />
        </div>
      )}

      <ControlledSelect
        control={control}
        labelDirection="row"
        options={timeZoneData}
        placeholder="Select timezone..."
        label="Time Zone"
        name="timeZone"
        rules={validation('TimeZone')}
        className="col-span-2"
      />
      {isPhysician && (
        <>
          {isMyProfile ? (
            <div className="col-span-2 flex h-max items-baseline gap-1">
              <div className="min-w-[120px] max-w-[120px] text-sm font-semibold">License type</div>
              <div className="flex flex-wrap gap-2">
                {!!licenseTypes?.length &&
                  licenseTypes.map((licenseType) => (
                    <Common.ColorTag
                      dataTestId="licenseTypes-tags"
                      color="gray"
                      key={licenseType.name}
                      text={licenseType?.name || ''}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <div className="col-span-2 h-max">
              <ControlledMultiSelect
                control={control}
                label="License type"
                name="licenseTypes"
                placeholder="Select license type..."
                options={licenseTypesOptions}
                rules={validation('License type')}
                labelDirection="row"
                isDisabled={isMyProfile}
              />
            </div>
          )}
        </>
      )}
      {isMyProfile ? (
        <div className="col-span-2 flex h-max items-baseline gap-1">
          <div className="min-w-[120px] text-sm font-semibold">Licensed in</div>
          <div className="flex flex-wrap gap-2">
            {!!licensedStates?.length &&
              licensedStates.map((state) => (
                <Common.ColorTag
                  dataTestId="licensed_in_state"
                  color="gray"
                  key={state}
                  text={state}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className="col-span-2 h-max">
          <ControlledMultiSelect
            control={control}
            label="Licensed in"
            name="licensedIn"
            placeholder="Select display licensed in..."
            options={licensedInOptions}
            rules={validation('Licensed in')}
            labelDirection="row"
          />
        </div>
      )}
      {shouldShowLanguages && (
        <>
          {isMyProfile ? (
            <div className="col-span-2 flex h-max items-baseline gap-1">
              <div className="min-w-[120px] max-w-[120px] text-sm font-semibold">
                Languages available to consult in
              </div>
              <div className="flex flex-wrap gap-2">
                {!!languages?.length &&
                  languages.map((language) => {
                    const currentLanguage = availableLanguages.find(
                      (item) => item.value === language
                    );
                    return (
                      <Common.ColorTag
                        dataTestId="languages"
                        color="gray"
                        key={language}
                        text={currentLanguage?.label || ''}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className="col-span-2 h-max">
              <ControlledMultiSelect
                control={control}
                label="Languages available to consult in"
                labelClasses={multiselectLabelClasses}
                name="languages"
                placeholder="Select available languages..."
                options={availableLanguages}
                labelDirection="row"
                {...(!isMyProfile && { rules: validation('Languages available to consult in') })}
              />
            </div>
          )}
        </>
      )}

      <div className="col-span-2 h-max">
        <ControlledMultiSelect
          control={control}
          label="Specialty areas"
          name="specialtyAreas"
          placeholder="Select display specialty areas..."
          options={specialtyAreasOptions}
          rules={validation('Specialty areas')}
          labelDirection="row"
        />
      </div>
      {isPhysician && (
        <div className="col-span-2 h-max">
          <InputField
            dataTestId="provider_video_url_field"
            name="providerVideo"
            label="Provider video"
            control={control}
            labelDirection="row"
            placeholder="https://vimeo.com/12345678"
            type="text"
            errors={formState.errors.providerVideo}
            rules={validation('Provider video', URL_REGEXP, null, true)}
          />
        </div>
      )}
      {hasPermission(EDIT_POPUP_PERMISSION.shortBio, currentRole) && (
        <ControlledTextArea
          control={control}
          name="shortBio"
          className="col-span-2 h-max"
          label="Short biography"
          placeholder="Enter short biography..."
          rows={2}
          errors={formState.errors.shortBio}
          labelDirection="row"
          helper={<span className="text-sm">Limit 500 characters.</span>}
        />
      )}

      <ControlledTextArea
        control={control}
        name="biography"
        className="col-span-2 h-max"
        labelDirection="row"
        placeholder="Enter biography..."
        label={isPhysician ? 'Long biography' : 'Biography'}
        rows={4}
        errors={formState.errors.biography}
        helper={
          <>
            <span className="text-sm font-bold">*bold* _italic_</span>
            <span className="text-sm">[Link text](https://linkurl.com)</span>
          </>
        }
        rules={validation('Biography')}
      />

      <div className="col-span-2 h-px w-full bg-gray-200" />

      <Controller
        control={control}
        name="education"
        render={({ field: { onChange, value, name } }) => (
          <div data-testid="education_section" className="col-span-2">
            <EducationGroupInputs
              dataTestId="education_field_scope"
              name={name}
              onChange={onChange}
              control={control}
              formState={formState}
              labelClasses={labelClasses}
              value={value || [{ value: '', id: nanoid() }]}
            />
          </div>
        )}
      />
    </>
  );
};

export default AdditionalSection;
