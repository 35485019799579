const PASSWORD_REGEXP = /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])[A-Za-z\d\W]{8,}$/;

const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const PREFIX_REGEXP = /^[a-zA-Z\-.]*$/;

const NAME_REGEXP = /([A-Za-z])+([ \-'`])?$/;

const NAME_REGEXP_EDIT_PROFILE = /^[a-z ,.'-]+$/i;

const FULL_NAME_REGEXP =
  /(^[A-Za-z\-'’ ]{2,})([ ][A-Za-z\-'’ ]{1,}\.?){0,}([ ]{1})([A-Za-z\-'’ ]{2,})$/;

const ANY_CHARACTER_REGEXP = /.*/;

const NUMBER_REGEXP = /^[0-9]*$/;

const PHONE_NUMBER_REGEXP = /^[0-9]{3}?[-\s\.][0-9]{3}[-\s\.][0-9]{4}$/;

const NUMBER_GREATER_THAN_ZERO_REGEXP = /^[1-9][0-9]*$/;

const DECIMAL_NUMBER_REGEXP = /^[0-9]*\.?[0-9]{0,2}$/;

const SIX_DIGIT_NUMBER_REGEXP = /^[0-9]{6}$/;

const AT_LEAST_ONE_LOWERCASE_REGEXP = /.*[a-z]+.*/;

const AT_LEAST_ONE_UPPERCASE_REGEXP = /.*[A-Z]+.*/;

const AT_LEAST_ONE_SPEC_CHAR_REGEXP = /[*@!#%$&()^~{}]+/;

const ADDRESS_REGEXP = /[A-Za-z0-9'.\-\s,]/;

const ZIP_REGEXP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const SPEC_CHARS_TO_ESCAPE_REGEXP = /([.?*+^$[\]\\(){}|-])/g;

const TRIMMED_REGEXP = /^\S+(?: \S+)*$/;
const TRIMMED_START_REGEXP = /^\S.*\s*$/;

const MM_DD_YYYY_REGEXP = /^\d{2}\/\d{2}\/\d{4}$/;

const REMOVE_TRAILING_LINE_BREAKS_AND_SPACES = /(<br\/?>|&nbsp;\s*)+(<\/[A-Za-z]+>)$/gm;
const REMOVE_LEADING_LINE_BREAKS_AND_SPACES = /^(<[A-Za-z]+>)(<br\/?>|&nbsp;\s*)+(.*)/gm;
const AT_LEAST_ONE_LETTER_REGEXP = /.*[A-Za-z]+.*/;

export {
  PASSWORD_REGEXP,
  EMAIL_REGEXP,
  URL_REGEXP,
  PREFIX_REGEXP,
  NAME_REGEXP,
  NUMBER_GREATER_THAN_ZERO_REGEXP,
  NAME_REGEXP_EDIT_PROFILE,
  FULL_NAME_REGEXP,
  ANY_CHARACTER_REGEXP,
  NUMBER_REGEXP,
  PHONE_NUMBER_REGEXP,
  DECIMAL_NUMBER_REGEXP,
  SIX_DIGIT_NUMBER_REGEXP,
  AT_LEAST_ONE_LOWERCASE_REGEXP,
  AT_LEAST_ONE_UPPERCASE_REGEXP,
  AT_LEAST_ONE_SPEC_CHAR_REGEXP,
  ADDRESS_REGEXP,
  ZIP_REGEXP,
  SPEC_CHARS_TO_ESCAPE_REGEXP,
  TRIMMED_REGEXP,
  MM_DD_YYYY_REGEXP,
  REMOVE_TRAILING_LINE_BREAKS_AND_SPACES,
  REMOVE_LEADING_LINE_BREAKS_AND_SPACES,
  AT_LEAST_ONE_LETTER_REGEXP,
  TRIMMED_START_REGEXP
};
