import { useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import DateLabel from 'components/common/Chat/DateLabel';
import Message from 'components/common/Chat/Message/Message';
import SystemMessage from 'components/common/Messages/SystemMessage';
import { MessageType, Status } from 'enums/messages';
import { useStaffNotes } from 'hooks/common/useStaffNotes';
import { useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';
import { selectFormattedStaffNotes, selectStaffNotes } from 'store/staffNotes/staffNotesSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

const selectStaffNotesListState = createSelector(
  [selectTask, selectFormattedStaffNotes, selectUser, selectStaffNotes],
  (task, formattedStaffNotes, user, staffNotes) => ({
    taskDetailsPatientId: task.taskDetails.personalInfo._id,
    staffNotes: formattedStaffNotes,
    userId: user._id,
    staffNotesLoadingStatus: staffNotes.staffNotesLoadingStatus
  })
);

const StaffNotesList: React.FC<{ handleImageClick: (filePath: string) => void }> = ({
  handleImageClick
}) => {
  const { taskDetailsPatientId, staffNotes, userId, staffNotesLoadingStatus } =
    useAppSelector(selectStaffNotesListState);
  const { patientId = '' } = useParams<{ patientId: string }>();
  const id = taskDetailsPatientId || patientId;
  const { loadMessages } = useStaffNotes({
    patientId: id
  });

  const { ref, inView } = useInView({ threshold: 1 });

  useEffect(() => {
    if (
      staffNotesLoadingStatus !== Status.Pending &&
      staffNotesLoadingStatus !== Status.Idle &&
      inView
    )
      loadMessages();
    // NOTE: must load messages once, only when inView change
  }, [inView]);

  return (
    <>
      {!isEmpty(staffNotes)
        ? Object.entries(staffNotes).map(([key, value], index) => {
            if (index === 0) {
              return (
                <div key={key}>
                  <DateLabel date={key} key={key} />
                  {value.map((message, messageIndex) => {
                    const messageDate = new Date(
                      message.deletedDate ? message.deletedDate : message.date
                    );

                    if (message?.system) {
                      return (
                        <SystemMessage
                          description={message.message}
                          date={new Date(message.date)}
                          self={false}
                          author={message.author.displayName}
                          key={message.id}
                        />
                      );
                    }
                    return (
                      <Message
                        date={messageDate}
                        deletedBy={message.deletedBy}
                        fileName={message.fileName}
                        handleImageClick={handleImageClick}
                        history={message.history}
                        id={message.id}
                        isGrouped={message.isGrouped}
                        isStaffNote={true}
                        key={message.id}
                        name={message.author.displayName}
                        profileImage={message?.profileImage}
                        ref={messageIndex === 0 ? ref : null}
                        self={userId === message.userId}
                        src={message.filePath}
                        text={message.message}
                        type={MessageType.StaffNote}
                        userType={message.author.userType}
                      />
                    );
                  })}
                </div>
              );
            } else {
              return (
                <div key={key}>
                  <DateLabel date={key} key={key} />
                  {value.map((message) => {
                    const messageDate = new Date(
                      message.deletedDate ? message.deletedDate : message.date
                    );

                    if (message?.system) {
                      return (
                        <SystemMessage
                          description={message.message}
                          date={new Date(message.date)}
                          self={false}
                          author={message.author.displayName}
                          key={message.id}
                        />
                      );
                    }
                    return (
                      <Message
                        date={messageDate}
                        fileName={message.fileName}
                        handleImageClick={handleImageClick}
                        deletedBy={message.deletedBy}
                        history={message.history}
                        id={message.id}
                        isGrouped={message.isGrouped}
                        isStaffNote={true}
                        key={message.id}
                        name={message.author.displayName}
                        profileImage={message?.profileImage}
                        self={userId === message.userId}
                        src={message.filePath}
                        text={message.message}
                        type={MessageType.StaffNote}
                        userType={message.author.userType}
                      />
                    );
                  })}
                </div>
              );
            }
          })
        : null}
    </>
  );
};

export default StaffNotesList;
